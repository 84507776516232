import LargeButton from "../../components/ui/LargeButton";
import Select from "../../components/ui/Select";

import styles from "./Roster.module.css";
import RosterTable from "./RosterTable";

const createTeamData = (
    id,
    name,
    rollers,
    rookie,
    timePrefs,
    noTimes,
    referredBy,
    notes
) => {
    return {
        id,
        name,
        rollers,
        rookie,
        timePrefs,
        noTimes,
        referredBy,
        notes,
    };
};

const teamRows = [
    createTeamData(
        38001,
        "24 Hour Party People",
        [38101, 38102, 38103],
        false,
        ["7:00", "6:30"],
        ["8:30"],
        "",
        ""
    ),
    createTeamData(
        38002,
        "4 Balls & A Doll",
        [38201, 38202, 38203],
        false,
        ["7:00", "6:30"],
        ["8:30"],
        "",
        ""
    ),
];

function createData(name, calories, fat, carbs, protein) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
    };
}

const rows = [
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Donut", 452, 25.0, 51, 4.9),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
    createData("Honeycomb", 408, 3.2, 87, 6.5),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Jelly Bean", 375, 0.0, 94, 0.0),
    createData("KitKat", 518, 26.0, 65, 7.0),
    createData("Lollipop", 392, 0.2, 98, 0.0),
    createData("Marshmallow", 318, 0, 81, 2.0),
    createData("Nougat", 360, 19.0, 9, 37.0),
    createData("Oreo", 437, 18.0, 63, 4.0),
];

const createPlayerData = (division, team, name, rollerName, email) => {
    return division, team, name, rollerName, email;
};

const playerRows = [
    createPlayerData(
        "Wednesday",
        "24 Hour Forty People",
        "Hannah Perned",
        "Skees Lost Control",
        "hannah.perned@gmail.com"
    ),
    createPlayerData(
        "Wednesday",
        "24 Hour Forty People",
        "Joe Canose",
        "Skeean Curtis",
        "sneakypants@gmail.com"
    ),
];

const filterOptions = [
    { name: "rookies", value: 1 },
    { name: "boogers", value: 2 },
    { name: "hookers", value: 3 },
];

const Roster = () => {
    return (
        <>
            <div className={styles.controls}>
                <LargeButton>Add Roller</LargeButton>
                <Select name="filter" label="filter" elements={filterOptions} />
            </div>
            <RosterTable data={rows} />
        </>
    );
};

export default Roster;
