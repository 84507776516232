import WeekChooser from "../../components/ui/stats/WeekChooser";
import styles from "./StatsWeekly.module.css";

const StatsWeekly = () => {
    return (
        <div>
            <WeekChooser />
        </div>
    );
};

export default StatsWeekly;
