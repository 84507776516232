import React from "react";

import Navigation from "./Navigation";

import styles from "./Header.module.css";
import Banner from "./Banner";

const Header = () => {
    return (
        <header className={styles["main-header"]}>
            <Banner />
            <Navigation />
        </header>
    );
};

export default Header;
