import React, { Suspense, Fragment } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Helmet from "react-helmet";

import Layout from "./components/layout/Layout";
import Home from "./pages/Home";
import Roster from "./pages/Roster/Roster";
import Schedule from "./pages/Schedule/Schedule";
// import Matches from "./pages/Schedule/Matches";
import Stats from "./pages/Stats/Stats";
import StatsWeekly from "./pages/Stats/StatsWeekly";
import StatsDashboard from "./pages/Stats/StatsDashboard";
import LoadingSpinner from "./components/ui/LoadingSpinner";

const Matches = React.lazy(() => import("./pages/Schedule/Matches"));

function App() {
    // let location = useLocation();

    return (
        <Fragment>
            <Helmet>
                <title>Skee-E-O Tools</title>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                ></meta>
            </Helmet>
            <Layout>
                <Suspense
                    fallback={
                        <div className="centered">
                            <LoadingSpinner />
                        </div>
                    }
                >
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/roster" element={<Roster />} />
                        <Route path="/schedule" element={<Schedule />} />
                        <Route path="/stats" element={<Stats />}>
                            <Route path="/stats" element={<StatsWeekly />} />
                            <Route
                                path="/stats/displays"
                                element={<StatsDashboard />}
                            />
                        </Route>
                        <Route path="/matches" element={<Matches />} />
                    </Routes>
                </Suspense>
            </Layout>
        </Fragment>
    );
}

export default App;
