import { NavLink } from "react-router-dom";

import styles from "./Navigation.module.css";

const Navigation = () => {
    return (
        <nav className={styles.nav__main}>
            <ul>
                <li>
                    <NavLink
                        className={(navData) =>
                            navData.isActive ? styles.active : ""
                        }
                        to="/"
                    >
                        Home
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className={(navData) =>
                            navData.isActive ? styles.active : ""
                        }
                        to="/roster"
                    >
                        Roster
                    </NavLink>
                </li>

                <li>
                    <NavLink
                        className={(navData) =>
                            navData.isActive ? styles.active : ""
                        }
                        to="/schedule"
                    >
                        Schedule
                    </NavLink>
                </li>

                <li>
                    <NavLink
                        className={(navData) =>
                            navData.isActive ? styles.active : ""
                        }
                        to="/stats"
                    >
                        Stats
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default Navigation;
