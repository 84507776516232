import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";

import styles from "./Layout.module.css";

const Layout = (props) => {
    let location = useLocation();

    return (
        <Fragment>
            {location.pathname !== "/matches" ? (
                <div className={styles.page}>
                    <Header />
                    <main>{props.children}</main>
                </div>
            ) : (
                <div className={styles.fullpage}>{props.children}</div>
            )}
        </Fragment>
    );
};

export default Layout;
