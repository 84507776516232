import styles from "./Banner.module.css";
import logo from "../../images/lanebot.png";
const Banner = () => {
    return (
        <div className={styles.banner}>
            <div className={styles.banner__brand}>
                <div className={styles["banner__image-container"]}>
                    <img
                        className={styles.banner__image}
                        src={logo}
                        alt="Skee-E-O Tools"
                    />
                </div>
                <h1>Skee-E-O Tools</h1>
                <div className={styles["banner__image-container"]}>
                    {/* <img
                        className={styles.banner__image}
                        src={logo}
                        alt="Skee-E-O Tools"
                    /> */}
                </div>
            </div>
            <div className={styles.banner__info}>
                <div className={styles["banner_spacer"]}></div>
                <div className={styles["banner__info-city"]}>City Name</div>
                <div className={styles["banner__info-user"]}>
                    Player Stuff<span className="material-icons">settings</span>
                </div>
            </div>
        </div>
    );
};

export default Banner;
