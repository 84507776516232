import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";

import styles from "./Stats.module.css";

const Stats = () => {
    return (
        <Fragment>
            <nav className={styles.nav}>
                <ul>
                    <li>
                        <NavLink
                            className={(navData) =>
                                navData.isActive ? styles.active : ""
                            }
                            to="/stats"
                        >
                            Weekly Stats
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={(navData) =>
                                navData.isActive ? styles.active : ""
                            }
                            to="/stats/displays"
                        >
                            Displays
                        </NavLink>
                    </li>
                </ul>
            </nav>
            <Outlet />
        </Fragment>
    );
};

export default Stats;
