import React from "react";
import Card from "../Card";

import styles from "./WeekChooser.module.css";

const weeks = [
    "Week 1",
    "Week 2",
    "Week 3",
    "Week 4",
    "Week 5",
    "Week 6",
    "Week 7",
    "Week 8",
];

const WeekChooser = (props) => {
    return (
        <Card>
            <nav className={styles.weeks__nav}>
                <ul>
                    {weeks.map((item, i) => {
                        return <li key={i}>{item}</li>;
                    })}
                </ul>
            </nav>
        </Card>
    );
};

export default WeekChooser;
