import styles from "./Select.module.css";
const Select = (props) => {
    // console.log(props.elements);
    const onChangeDropdown = (event) => {
        console.log(event.target.value);
    };

    return (
        <div className={styles["select__wrapper"]}>
            <label className={styles["select__label"]} htmlFor={props.name}>
                {props.label}
            </label>
            <select
                className={styles["select__dropdown"]}
                name={props.name}
                id={props.name}
                onChange={onChangeDropdown}
            >
                {props.elements.map((element, index) => {
                    return (
                        <option key={index} value={element.value}>
                            {element.name}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};

export default Select;
